module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mnguyen.io"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600,"showCaptions":["title"],"markdownCaptions":true,"withWebp":true,"linkImagesToOriginal":true,"tracedSVG":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600,"showCaptions":["title"],"markdownCaptions":true,"withWebp":true,"linkImagesToOriginal":true,"tracedSVG":{"color":"#546fc4","turnPolicy":"TURNPOLICY_LEFT","turdSize":100,"optCurve":true,"optTolerance":0.4,"threshold":-1,"blackOnWhite":true,"background":"transparent"},"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Minh Nguyen | Sydney Full Stack Web Developer","short_name":"Minh Nguyen","start_url":"/","background_color":"#003380","theme_color":"#003380","display":"standalone","icon":"src/images/mnguyen-logo-square.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae190770ec6b07a8164a47349808a986"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
